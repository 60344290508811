<template>
  <v-row no-gutters justify="center" style="height:80vh" align="center">
    <v-fade-transition>
      <v-card rounded="lg" v-show="imageReady" flat class="pa-5" width="370" max-width="370">
        <v-row class="mb-8" no-gutters justify="center">
          <img
            id="loginImage"
            class="mx-5 px-7 pt-5 mt-5"
            style="width: 100%; max-height: 200px; object-fit: contain;"
            :src="require('@/assets/Arrowhealth-logo-RGB.png')"
          />
        </v-row>

        <v-form ref="loginForm">
          <v-text-field
            :readonly="emailLocked"
            outlined
            label="Email"
            name="email"
            type="text"
            required
            v-model="email"
            :rules="emailRules"
            v-on:keyup.enter="submitLogin"
          />

          <v-text-field
            ref="password"
            outlined
            id="password"
            label="Password"
            name="password"
            :append-icon="isPassVisible ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="() => (isPassVisible = !isPassVisible)"
            :type="isPassVisible ? 'text' : 'password'"
            required
            v-model="password"
            :rules="passwordRules"
            v-on:keyup.enter="submitLogin"
          />
        </v-form>

        <v-row justify="space-between" no-gutters>
          <v-btn v-if="shouldAllowSignup" color="primary" text @click="switchToRegister">
            Sign Up
          </v-btn>
          <v-btn color="primary" text @click="resetPassword">
            Forgot Password?
          </v-btn>
          <v-spacer />
          <v-btn color="primary" depressed @click="submitLogin">
            <v-fade-transition hide-on-leave>
              <span v-show="!loginLoading">
                Log In
              </span>
            </v-fade-transition>
            <v-fade-transition hide-on-leave>
              <v-progress-circular class="px-6" v-show="loginLoading" size="20" indeterminate />
            </v-fade-transition>
          </v-btn>
        </v-row>
      </v-card>
    </v-fade-transition>
  </v-row>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapState } from 'vuex'
import bus from '@/core/helpers/bus'
import HelperMixin from '@/core/mixins/HelperMixin'
import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  mixins: [HelperMixin],
  name: 'loginpage',
  components: {},
  data() {
    return {
      imageReady: false,
      orgInviteToken: null,
      loginLoading: false,
      isPassVisible: false,
      patient: null,
      email: null,
      emailLocked: false,
      password: null,
      emailRules: [v => !!v || 'E-mail is required'],
      passwordRules: [v => !!v || 'Password is required'],
      shouldAllowSignup: false,
    }
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  watch: {
    user(val) {
      if (!val) {
        return
      }
      // user is back. if there is no token to apply, just go to the correct page. if there is a token, apply the token first
      // this will be called again when the token is applied.
      if (this.orgInviteToken) {
        try {
          const t = this.orgInviteToken
          this.orgInviteToken = null
          this.acceptOrgInvite(t)
        } catch (e) {
          bus.$emit('toast', { type: 'error', text: 'Could not apply organization invitation: ' + e })
        }
      }
      this.$router.push({ name: 'Inbox' })
    },
  },
  methods: {
    ...mapActions('auth', ['acceptOrgInvite', 'signOut']),
    parseURLParameters() {
      const search = this.$route.query
      if (search.orgInviteToken) {
        this.orgInviteToken = search.orgInviteToken
      }
      if (search.email) {
        this.email = decodeURIComponent(search.email)
        this.emailLocked = true
      }
    },
    switchToRegister() {
      this.$router.push({ name: 'Register', query: this.$route.query })
    },
    resetPassword() {
      this.$router.push({ name: 'PasswordReset' })
    },
    async submitLogin() {
      if (!this.$refs.loginForm.validate()) {
        return
      }
      this.loginLoading = true
      try {
        await firebase.auth().signInWithEmailAndPassword(this.email, this.password)
      } catch (e) {
        console.log('firebase sign in error: ' + e)
        switch (e.code) {
          case 'auth/user-not-found':
            bus.$emit('toast', { type: 'error', text: 'User not found. Sign up instead?' })
            break
          case 'auth/invalid-email':
            bus.$emit('toast', { type: 'error', text: 'Invalid Email' })
            break
          case 'auth/wrong-password':
            bus.$emit('toast', { type: 'error', text: 'Invalid password' })
            break
          default:
            bus.$emit('toast', { type: 'error', text: e.code })
        }
        this.loginLoading = false
      }
    },
  },
  beforeMount() {
    this.parseURLParameters()
  },
  mounted() {
    // only show the rest when the image has been loaded
    let img = document.getElementById('loginImage')
    const vm = this
    img.onload = function() {
      vm.imageReady = true
    }

    img.onerror = function() {
      vm.imageReady = true
    }
    this.signOut()
  },
}
</script>
